.header-container {
  .top-navigation {
    background: #f9fafa;

    .zv-title {
      font-weight: 600;
    }
  }

  .menu-container {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
  }
}

.nav-drawer .drawer-list {
  width: 86vw;
  max-width: 400px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 0;

  .close-button {
    justify-content: flex-end;
    padding-left: 16px;
    padding-right: 16px;
  }

  @media only screen and (max-width: 380px) {
    .close-button {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
