.contact-container {
  .contact-input {
    margin-bottom: 30px;
  }

  .submit-button {
    display: block;
  }

  .text-icon {
    vertical-align: middle;
    display: inline-flex;
  }

  .form-text-field {
    background-color: #ffffff;
  }
}
