.projects-container {
  .project-section {
    margin-bottom: 50px;

    .project-image {
      width: 100%;
      margin-bottom: 10px;
    }

    .sub-section {
      margin-bottom: 25px;
      white-space: pre-wrap;
    }
  }
}
