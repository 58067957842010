.resume-container {
  .experience-card:not(:last-child) {
    margin-bottom: 30px;
  }

  .body-paragraph:not(:last-child) {
    margin-bottom: 10px;
  }

  .download-button {
    margin-bottom: 30px;
  }
}
