.footer-container {
  min-height: 90px;
  width: 100%;
  left: 0;
  bottom: 0;
  position: absolute;
  background-color: #d5d5d5;

  .content-wrapper {
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;

    .social-links {
      display: flex;
      justify-content: center;
    }
  }
}
